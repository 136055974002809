import { createGlobalStyle } from 'styled-components';

export type FontFamily =
  | 'fontRegular'
  | 'fontMedium'
  | 'secondaryRegular'
  | 'secondaryBold'
  | 'secondaryMedium';

const fontFamilies = `
@font-face {
  font-family: 'fontRegular';
  src: url('/Static/css/fonts/Inter-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'fontMedium';
  src: url('/Static/css/fonts/Inter-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'secondaryRegular';
  src: url('/Static/css/fonts/Manrope-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'secondaryMedium';
  src: url('/Static/css/fonts/Manrope-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'secondaryBold';
  src: url('/Static/css/fonts/Manrope-Bold.woff2') format('woff2');
  font-display: swap;
}
`;

// Handle font families outside of global styles to avoid repeated font downloads
// Font families are loaded in ThemeProvider.tsx and defined in tokens.ts using the type FontFamily (declared above).
export const FontFamilies = () => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: fontFamilies,
      }}
    />
  );
};

export const GlobalStyles = createGlobalStyle`
  /* Global Styles */

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    font-family: fontRegular;
    font-size: 16px;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: fontRegular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    min-height: 100%;
    
    &.hide-scrollbar::-webkit-scrollbar {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  main {
    min-height:  100vh;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  hr {
    box-sizing: content-box;
    border-top-width: 1px;
    height: 0;
    overflow: visible;
  }

  pre, code, kbd, samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  a {
    background-color: transparent;
    color: inherit;
    text-decoration: inherit;
  }

  abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  b, strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -0.25em;
  }

  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
  }

  img {
    border-style: solid;
  }

  button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    padding: 0;
    color: inherit;
  }

  button, input {
    overflow: visible;
  }

  button, select {
    text-transform: none;
  }

  button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  fieldset {
    padding: 0.35em 0.75em 0.625em;
    margin: 0;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  [type="checkbox"], [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  [type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  template, [hidden] {
    display: none;
  }

  blockquote, dl, dd, h4, h5, h6, hr, figure, p, button {
    margin: 0;
  }

  button {
    background-color: transparent;
    background-image: none;
    padding: 0;
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  *, ::before, ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
  }

  input:-ms-input-placeholder, textarea:-ms-input-placeholder, input::-ms-input-placeholder, textarea::-ms-input-placeholder, input::placeholder, textarea::placeholder {
    color: #a0aec0;
  }

  button, [role="button"] {
    cursor: pointer;
    &:focus-visible {
      outline: ${(props) =>
        props.theme.colors.interactiveBorderSelected} double 0.375rem;
      box-shadow: ${(props) => props.theme.colors.surface} 0px 0px 0px 0.25rem;
    }  
  }

  table {
    border-collapse: collapse;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
  }

  pre, code, kbd, samp {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  img, svg, video, canvas, audio, iframe, embed, object, img, video {
    display: block;
    vertical-align: middle;
  }

  img, video {
    max-width: 100%;
    height: auto;
  }

  html.wf-active body {
    font-family: fontRegular, Arial;
    line-height: 1.625;
  }

  body:not(.ie) #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  body:not(.ie) main {
    flex-grow: 1;
    width: 100%;
  }

  #root {
    background-color: ${(props) => props.theme.colors.surface};
  }

  :focus:not(:focus-visible) {
    outline: none;
  }
 
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }

  @media print {
    body:not(.IE) #root {
      display: block;
    }
  }
`;

export default GlobalStyles;
