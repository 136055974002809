import { ExceptionResponse, FailureResponse, http } from 'app/fetch/fetcher';
import { plainState } from 'app/store';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { navigate } from 'Kex/KexPage';
import { GenericPageState } from './currentPageSlice';
const fetchPageUrl = `api/content/page`;

export const fetchPage = (
  url: string,
  onError: (error: FailureResponse | ExceptionResponse) => void
) => {
  const target = `${import.meta.env.VITE_USE_MIDDLELAYER === 'true' ? '' : getAPIBasedOnSystem('cms')}${location.pathname}${location.search}`;
  return http
    .handleHttpResponse<GenericPageState>(
      http.get(url, {
        headers: { 'Context-Url': target },
      }),
      undefined,
      (err) => err && onError(err)
    )
    .then((response) => {
      return response;
    });
};

export const fetchCurrentPageAsync = createAsyncThunk(
  'currentPage/fetchCurrentPage',
  async () => {
    const target = `${getAPIBasedOnSystem('cms')}/${fetchPageUrl}`;
    return fetchPage(target, (error: FailureResponse | ExceptionResponse) => {
      switch (error.status) {
        case 404:
          if (
            window.location.pathname !== plainState.getUnsafeValue(404) &&
            plainState.getUnsafeValue(404)
          ) {
            navigate(plainState.getUnsafeValue(404));
          }
          break;
        default:
          //Catch all other errors
          if (
            window.location.pathname !== plainState.getUnsafeValue(500) &&
            plainState.getUnsafeValue(500)
          ) {
            navigate(plainState.getUnsafeValue(500));
          }
          break;
      }
    });
  }
);
