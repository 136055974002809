import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { setError } from '../appError/appErrorSlice';
import { fetchResource, postResource } from '../utils/sliceUtils';
import { FavoritesState, UpdateFavorite } from './favoritesSlice';

// API endpoint for favorites related operations
const favoritesApiUrl: string = 'api/favorites';

/**
/**
 * AsyncThunk to fetch the current state of the favorites.
 * Makes a fetch request to the `favoritesApiUrl` and expects a response of type `IFavoritesState`.
 */
export const fetchFavoriteStateAsync = createAsyncThunk(
  'favorites/get',
  (_, { dispatch }) => {
    return fetchResource<FavoritesState>(
      `${getAPIBasedOnSystem('cms')}/${favoritesApiUrl}`,
      dispatch,
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    );
  }
);

/**
 * AsyncThunk to update the current state of the favorites.
 * Makes a fetch request to the `favoritesApiUrl` and expects a response of type `IFavoritesState`.
 */
export const updateFavoriteStateAsync = createAsyncThunk(
  'favorites/update',
  (item: UpdateFavorite, { dispatch }) => {
    return postResource<FavoritesState>(
      favoritesApiUrl,
      dispatch,
      item,
      (err) => dispatch(setError(err)),
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    );
  }
);
