// userSlice.ts

// Importing essential utilities from Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';
// Importing the app's dispatch type
import { AppDispatch, useAppSelector } from 'app/store';
// Importing React-Redux hook for dispatching actions
import { useDispatch } from 'react-redux';
// Importing user-related async actions
import {
  fetchCustomerStateAsync,
  signOutUser,
  registerUser,
  signInUser,
} from './customer';

export interface Address {
  id?: string;
  email?: string;
  addressName?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  city?: string;
  postalCode?: string;
  countryName?: string;
  countryCode?: string;
  address?: string;
  isPrimaryAddress?: boolean;
}
/**
 * Type definition for the user's state in the Redux store.
 */
export interface CustomerState {
  id: number | undefined; // Unique ID of the user
  firstName: string | undefined; // Name of the user
  lastName: string | undefined; // Name of the user
  phoneNumber: string | undefined; // Phone number of the user
  email: string | undefined; // Email address of the user
  address?: Address;
  isLoading: boolean; // Indicates if a user action is ongoing
  status: 'idle' | 'loading' | 'succeeded' | 'failed'; // Current status of user actions
  authenticated?: boolean; // Indicates if the user is logged in
  error?: string; // Holds any error messages
  authenticateToken?: string;
  billingAddress?: Address;
  deliveryAddresses?: Address[];
}

// Default state when the app starts or after a user logs out
export const initialState: CustomerState = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  email: undefined,
  isLoading: false,
  authenticated: undefined,
  status: 'idle',
};

// A slice to manage all user-related data and actions in Redux
const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // When login action starts, set status to loading
      .addCase(signInUser.pending, (state) => {
        state.status = 'loading';
      })
      // Once login is successful, update user details and set authenticated to true
      .addCase(signInUser.fulfilled, (state, action) => {
        const result = action.payload;
        if (result?.isSuccess) {
          const { data } = result;
          state.status = data.email ? 'succeeded' : 'failed';
          state.id = data.id;
          state.firstName = data.firstName;
          state.lastName = data.lastName;
          state.phoneNumber = data.phoneNumber;
          state.email = data.email;
          state.address = data.address;
          state.authenticated = data.email ? true : false;
          state.billingAddress = data.billingAddress;
          state.deliveryAddresses = data.deliveryAddresses;
        }
        //TODO: Handle error cases
      })
      // In case of login failure, record the error message
      .addCase(signInUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Reset user state to initial upon successful logout
      .addCase(signOutUser.fulfilled, () => {
        return { ...initialState, authenticated: false };
      })
      // When login action starts, set status to loading
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      // Once login is successful, update user details and set authenticated to true
      .addCase(registerUser.fulfilled, (state, action) => {
        const result = action.payload;
        state.isLoading = false;
        if (result?.isSuccess) {
          const { data } = result;
          state.status = data.email ? 'succeeded' : 'failed';
          state.id = data.id;
          state.firstName = data.firstName;
          state.lastName = data.lastName;
          state.phoneNumber = data.phoneNumber;
          state.email = data.email;
          state.authenticated = data.email ? true : false;
          state.billingAddress = data.billingAddress;
          state.deliveryAddresses = data.deliveryAddresses;
        } else if (result?.isFailure) {
          state.status = 'failed';
          state.error = '@@Error';
        } else if (result?.isException) {
          state.status = 'failed';
          state.error = '@@Error';
        }
      })
      .addCase(registerUser.rejected, (state) => {
        state.isLoading = false;
      })
      // Update user state when fetching user details succeeds
      .addCase(fetchCustomerStateAsync.fulfilled, (state, action) => {
        const result = action.payload;

        if (result?.isSuccess) {
          const { data } = result;
          return {
            ...state,
            status: data.email ? 'succeeded' : 'failed',
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            authenticated: data.email ? true : data.authenticated,
            authenticateToken: data.authenticateToken,
            address: data.address,
            billingAddress: data.billingAddress,
            deliveryAddresses: data.deliveryAddresses,
          };
        }

        state.authenticated = false;
        return state;
      });
  },
});

// Custom hook to provide easy access to dispatch user-related actions
export const useFetchCustomer = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Function to dispatch the fetchUserStateAsync action
  function fetchUser() {
    dispatch(fetchCustomerStateAsync());
  }

  return {
    fetchUser,
  };
};

// Exporting the reducer to be used in the store
export default customerSlice.reducer;

export const useCustomer = () => useAppSelector((state) => state.customerState);
