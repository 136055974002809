import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from 'app/fetch/fetcher';
import { FeatureManagement } from 'app/shared/globalSettings/FeatureManagement';
import { AppDispatch, fetchResources } from 'app/store';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { setError } from '../appError/appErrorSlice';
import { StaticPage } from './globalSettingsSlice';
export interface GlobalSettingsStateResponse {
  currency: string; // Currency type (e.g., 'USD', 'SEK')
  market: string; // Market type (e.g., 'US', 'SE')
  marketThreeLetterCode: string; // Market type in three letters (e.g., 'USA', 'SWE')
  languageRoute: string; // Language route for the application (e.g., 'en', 'sv')
  gtmId?: string; // Google Tag Manager ID (optional)
  insightKey?: string; // Application Insight Instrumentation Key (optional)
  staticPages: { [key: string]: { value: StaticPage } }; // Key-value pairs representing static pages, useful for error pages like 404
  featureManagement?: { [key in FeatureManagement]: boolean };
}

// Global settings URL (will be moved to a shared location later)
const url: string = 'api/site/global-settings';

export const fetchGlobalStateAsync = createAsyncThunk(
  'globalSettings/fetch',
  ({ error }: { error?: () => void }, { dispatch }) => {
    return http
      .handleHttpResponse<GlobalSettingsStateResponse>(
        http.get(`${getAPIBasedOnSystem('cms')}/${url}`),
        dispatch,
        () =>
          error
            ? error()
            : dispatch(() =>
                setError({
                  titleTranslationKey: 'Error/TechnicalError',
                  messageTranslationKey: 'Error/TechnicalErrorDescription',
                })
              )
      )
      .then((result) => {
        fetchResources(dispatch as AppDispatch);
        return result;
      });
  }
);
