import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { setError } from '../appError/appErrorSlice';
import { PriceHelpers } from '../globalSettings/priceHelpers';
import {
  deleteResource,
  fetchResource,
  postResource,
  putResource,
} from '../utils/sliceUtils';
import { CartState, CartUpdateItem, LineItemModelEXT } from './cartSlice';
import { AppDispatch } from 'app/store';
import { http } from 'app/fetch/fetcher';

// API endpoint for cart related operations
const url: string = 'api/cart';

/**
 * AsyncThunk to fetch the current state of the cart.
 * Makes a fetch request to the `url` and expects a response of type `ICartState`.
 */
export const fetchCartStateAsync = createAsyncThunk(
  'cart/fetch', // Action type prefix (NOTE: To be changed later)
  (_, { dispatch }) => {
    return fetchResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}`,
      dispatch,
      undefined,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    );
  }
);

/**
 * AsyncThunk to update the current state of the cart.
 * Makes a fetch request to the `url` and expects a response of type `ICartState`.
 */
interface UpdateCartProps {
  item: CartUpdateItem;
  getPrice: ReturnType<PriceHelpers['getPrice']>;
  oldLineItems?: LineItemModelEXT[];
  dispatch?: AppDispatch;
}

export interface updateBulkCartProps {
  code: string;
  quantity: number;
}

export const updateCartStateAsync = createAsyncThunk(
  'cart/update/fetch', // Action type prefix (NOTE: To be changed later)
  ({ item, getPrice }: UpdateCartProps, { dispatch }) => {
    return postResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}`,
      dispatch,
      item,
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    ).then((result) => ({ result, getPrice, item }));
  }
);

export const updateCartState = ({
  item,
  getPrice,
  dispatch,
}: UpdateCartProps) => {
  return http
    .handleHttpResponse<CartState>(
      http.post<CartState>(`${getAPIBasedOnSystem('commerce')}/${url}`, {
        body: item,
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }),
      dispatch,
      (err) => err?.data && setError && setError(err.data)
    )
    .then((result) => ({ result, getPrice }));
};

export const updateCartQuantityStateAsync = createAsyncThunk(
  'cart/update/quantity', // Action type prefix (NOTE: To be changed later)
  ({ item, getPrice, oldLineItems }: UpdateCartProps, { dispatch }) => {
    return putResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}`,
      dispatch,
      item,
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    ).then((result) => ({ result, getPrice, oldLineItems }));
  }
);

export const updateBulkCart = createAsyncThunk(
  'cart/update/bulk', // Action type prefix (NOTE: To be changed later)
  (
    {
      items,
      getPrice,
    }: {
      items: updateBulkCartProps[];
      getPrice: ReturnType<PriceHelpers['getPrice']>;
    },
    { dispatch }
  ) => {
    return postResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}/add-multiple`,
      dispatch,
      items,
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    ).then((result) => ({ result, getPrice }));
  }
);

export const removeCartStateAsync = createAsyncThunk(
  'cart/remove/fetch', // Action type prefix (NOTE: To be changed later)
  (_, { dispatch }) => {
    return deleteResource<CartState>(`${url}`, dispatch, setError);
  }
);
export const addDiscountCode = createAsyncThunk(
  'cart/update/discountcode', // Action type prefix (NOTE: To be changed later)
  (
    {
      discountCode,
    }: {
      discountCode: string;
    },
    { dispatch }
  ) => {
    return postResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}/add-discount-code?discountCode=${discountCode}`,
      dispatch,
      {}, // body
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    ).then((result) => ({ result }));
  }
);

export const removeDiscountCode = createAsyncThunk(
  'cart/remove/discountcode', // Action type prefix (NOTE: To be changed later)
  (
    {
      discountCode,
    }: {
      discountCode: string;
    },
    { dispatch }
  ) => {
    return postResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}/remove-discount-code?discountCode=${discountCode}`,
      dispatch,
      {}, // body
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    ).then((result) => ({ result }));
  }
);

export const sendCart = createAsyncThunk(
  'cart/send', // Action type prefix (NOTE: To be changed later)
  (
    {
      email,
    }: {
      email: string;
    },
    { dispatch }
  ) => {
    return postResource<CartState>(
      `${getAPIBasedOnSystem('commerce')}/${url}/send?email=${email}`,
      dispatch,
      {}, // body
      setError,
      {
        headers: {
          System: import.meta.env.VITE_TARGET_COMMERCE,
        },
      }
    ).then((result) => ({ result }));
  }
);
