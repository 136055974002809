import { createSlice } from '@reduxjs/toolkit';
import {
  fetchHeaderAsync,
  fetchHeaderDataAsync,
} from 'DesignComponents/Organisms/Header/DesktopHeader/HeaderTop/HeaderTopFetcher';
import HeaderModel from 'Models/Headers/HeaderModel.interface';
import { useAppDispatch, useAppSelector } from 'app/store';

const initialState: HeaderModel = {
  links: [],
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHeaderAsync.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return result.data;
      }
      return state;
    });
    builder.addCase(fetchHeaderAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
  },
});

export const useFetchHeader = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(fetchHeaderDataAsync());
};

export const useHeaderData = <T extends HeaderModel>() =>
  useAppSelector((state) => state.headerData) as T;

export default headerSlice.reducer;
