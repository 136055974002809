import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from 'app/fetch/fetcher';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { OrderInfoModel } from './OrderInfoModel';
import OrderModel, {
  CreateOrderRequestModel,
  OrderHistoryModel,
} from './OrderModel';

export const getConfirmationOrder = (orderId: string) => {
  return http.handleHttpResponse<OrderInfoModel>(
    http.get('api/order/confirmation?id=' + orderId)
  );
};

export const fetchAllOrders = createAsyncThunk(
  'orders/getOrders',
  async (params: { year: string; take: number; skip: number }) => {
    const { year, take, skip } = params;
    return http.handleHttpResponse<OrderHistoryModel>(
      http.get(
        `${getAPIBasedOnSystem('commerce')}/api/order/all-orders?year=${year}&take=${take}&skip=${skip}`
      )
    );
  }
);

export const fetchOrder = createAsyncThunk(
  'orders/getOrder',
  async (orderId: string) => {
    return http.handleHttpResponse<OrderModel>(
      http.get(`${getAPIBasedOnSystem('commerce')}/api/order/${orderId}`)
    );
  }
);

export const submitOrder = createAsyncThunk(
  'checkout/submitOrder',
  async (orderRequestModel: CreateOrderRequestModel) => {
    return http.handleHttpResponse<string>(
      http.post(`${getAPIBasedOnSystem('commerce')}/api/order/`, {
        body: orderRequestModel,
      })
    );
  }
);
