import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResultItemsProps } from 'Commerce/Molecules/QuickOrder/ResultItem';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { http } from 'app/fetch/fetcher';
import { BulkUpdateProps } from 'app/shared/quickOrder/bulkUpdateCartSlice';

const url: string = 'api/search/variation-code-search';
const bulkCartUpdateUrl: string = 'api/cart/bulk';
export const abortController = new AbortController();

export const VariationCodeSearch = createAsyncThunk(
  'search/code-search',
  async (query: string) => {
    return http.handleHttpResponse<ResultItemsProps>(
      http.get(`${getAPIBasedOnSystem('commerce')}/${url}?query=${query}`)
    );
  }
);

export const BulkCartUpdate = createAsyncThunk(
  'cart/bulk-update',
  async (query: string[]) => {
    const requestQuery = {
      lineitems: query,
    };
    return http.handleHttpResponse<BulkUpdateProps>(
      http.post(bulkCartUpdateUrl, {
        body: requestQuery,
        signal: abortController.signal,
      })
    );
  }
);
