import styled, { DefaultTheme } from 'styled-components';
import ColorTokensType from 'DesignSystem/StyledComponets/Settings/colorTypes';
import { Themes } from 'DesignSystem/StyledComponets/Theme/Theme';

export const sizeVariations = {
  s: `
    width: 16px;
    height: 16px;
  `,
  m: `
    width: 24px;
    height: 24px;
  `,
  l: `
    width: 32px;
    height: 32px;
  `,
  xl: `
    width: 64px;
    height: 64px;
  `,
  logo: `
    width: 148px;
    height: 30px;
  `,
  dot: `
    width: 6px;
    height: 6px;
  `,
};

export type IconColorName = keyof ColorTokensType | 'transparent';

const pathStrokeRound = `
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export type SizeVariations = keyof typeof sizeVariations;

type BaseSvgStyleProps = {
  color?: IconColorName;
  size?: SizeVariations;
  stroke?: string;
  fill?: string;
  strokeRound?: boolean;
};

export const getColor = (
  theme: DefaultTheme | Themes,
  color?: IconColorName
): string => {
  if (color === 'transparent') {
    return 'transparent';
  }

  return color ? theme.colors[color] : '';
};

export const BaseSvgStyle = styled.svg<BaseSvgStyleProps>`
  fill: transparent;
  ${(props) => props.size && sizeVariations[props.size]}
`;

export const BaseIconPathStyle = styled.path.withConfig({
  shouldForwardProp: (propName) => propName !== 'strokeRound',
})<BaseSvgStyleProps>`
  stroke-width: 1.5px;
  ${(props) => props.strokeRound && pathStrokeRound}
`;
