import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { setError } from '../appError/appErrorSlice';
import { fetchResource } from '../utils/sliceUtils';
import { SiteStructureResponse } from './navigationSlice';

// navigation URL
const navigationUrl = 'api/content/get-site-structure';

// AsyncThunk for fetching the global state
export const fetchNavigationStateAsync = createAsyncThunk(
  'navigation/fetch', // Action type prefix (NOTE: To be changed later)
  (_, { dispatch }) => {
    return fetchResource<SiteStructureResponse>(
      `${getAPIBasedOnSystem('cms')}/${navigationUrl}`,
      dispatch,
      setError
    );
  }
);
