import { keyframes, css } from 'styled-components';
import { FontFamily } from '../global.styled';

import { ObjectEntries } from 'app/shared/utils/utils';

// Define standard border widths for various UI components.
const borders = {
  default: '0.063rem',
  thick: '0.125rem',
  thicker: '0.188rem',
  focus: '0.375rem',
} as const;

// Set of predefined font sizes for consistent typography across the app.
const fontSizes = {
  fs75: '0.75rem',
  fs87: '0.875rem',
  fs100: '1rem',
  fs112: '1.125rem',
  fs125: '1.25rem',
  fs137: '1.375rem',
  fs150: '1.5rem',
  fs200: '2rem',
  fs300: '3rem',
  fs350: '3.5rem',
} as const;

// Mapping of font families for different textual elements.
const fontFamilies: Record<FontFamily, FontFamily> = {
  fontMedium: 'fontMedium',
  fontRegular: 'fontRegular',
  secondaryBold: 'secondaryBold',
  secondaryRegular: 'secondaryRegular',
  secondaryMedium: 'secondaryMedium',
} as const;

// Font weight definitions for typographic emphasis.
const fontWeights = {
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
} as const;

// Letter spacing values for fine-tuning text appearance.
const letterSpacings = {
  letterSpacingS: '0em',
  letterSpacingM: '0.01em',
  letterSpacingL: '0.1em',
} as const;

// Line height settings for different text sizes.
const lineHeights = {
  lh120: '120%',
  lh122: '122%',
  lh124: '124%',
  lh125: '125%',
  lh126: '126%',
  lh128: '128%',
  lh132: '132%',
  lh133: '133%',
  lh140: '140%',
  lh144: '144%',
  lh150: '150%',
  lh155: '155%',
  lh156: '156%',
  lh157: '157%',
  lh160: '160%',
  lh164: '164%',
  lh171: '171%',
} as const;

// Border radius values to standardize the curvature of UI elements.
const radius = {
  rExtraSmall: '0.25rem',
  rSmall: '0.5rem',
  rMedium: '1rem',
  rMediumLarge: '1.5rem',
  rLarge: '2rem',
  rLarger: '2.5rem',
  rMax: '4rem',
} as const;

// Shadow styles for depth effects in UI components.
const shadows = {
  ElevationOne: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  OverlayOne: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  focusVisible: '#fff 0px 0px 0px 0.25rem',
  focusVisibleInverted: '#000 0px 0px 0px 0.25rem',
} as const;

// Spacing scale for consistent margins and paddings.
const space = {
  s25: '0.25rem',
  s37: '0.375rem',
  s50: '0.5rem',
  s75: '0.75rem',
  s100: '1rem',
  s125: '1.25rem',
  s150: '1.5rem',
  s175: '1.75rem',
  s200: '2rem',
  s250: '2.5rem',
  s275: '2.75rem',
  s300: '3rem',
  s350: '3.5rem',
  s375: '3.75rem',
  s400: '4rem',
  s475: '4.75rem',
  s500: '5rem',
  s600: '6rem',
  s750: '7.5rem',
  s1000: '10rem',
  s1900: '19rem',
  grid16: '1rem',
  grid24: '1.5rem',
  grid32: '2rem',
  grid48: '3rem',
  grid60: '4rem',
} as const;

// Z-index values to manage stacking order of elements.
const zIndices = {
  zIndex1: 1,
  zIndex2: 2,
  CartMobile: 4,
  ConfiguratorSummary: 4,
  FlyoutLower: 5,
  OverlayMegaMenu: 5,
  ProductCardSecondary: 6,
  ProductCardMain: 7,
  ProductCardTag: 8,
  ProductCardFavorite: 8,
  Minicart: 9,
  Notification: 9,
  Ribbon: 9,
  DropDown: 9,
  NativeDropdown: 10,
  Header: 10,
  HeaderMobile: 10,
  Flyout: 10,
  QuantityDropDown: 10,
  Cart: 10,
  HeaderTop: 11,
  ModalOverlay: 19,
  Modal: 20,
  MobileQuickSearch: 21,
  Tooltip: 40,
  pageLoader: 990,
  errorModal: 1000,
} as const;

// Transition durations for smooth animations across the UI.
const transitions = {
  t100: '100ms',
  t200: '200ms',
  t250: '250ms',
  t300: '300ms',
  t400: '400ms',
  t500: '500ms',
  t600: '600ms',
  t700: '700ms',
  t1000: '1000ms',
  t1400: '1400ms',
  t1500: '1500ms',
  t5000: '5000ms',

  //Depricated
  '200': '200ms',
  '300': '300ms',
  '400': '400ms',
  '500': '500ms',
  '600': '600ms',
  '700': '700ms',
  '1000': '1000ms',
  '1500': '1500ms',
  '5000': '5000ms',
} as const;

// Size constants for various UI elements, ensuring consistent sizing.
export const sizeConstants = {
  videoMaxHeight: 35.875,
  modalHeight: 46,
  standardBlockLargerImageHeight: 30.625,
  standardBlockLargeImageHeight: 30,
  standardBlockMediumImageHeight: 21.563,
  thirdWidthBlockImageHeight: 19.5,
  standardBlockSmallImageHeight: 15.625,
  verySmallBlockImageHeight: 11.25,
  mobileHeaderHeight: 4,
  desktopHeaderHeight: 8,
  desktopConfiguratorBarHeight: 12.313,
  desktopHeaderUpperHeight: 4.5,
  desktopCheckoutHeaderUpperHeight: 3.5,
  mobileConfiguratorBarHeight: 5,
  configuratorImageHeightSmall: 17.5,
  configuratorImageHeightMedium: 30,
  quantityDropdownHeight: 12.5,

  //widths
  contentMaxWidth: 82,
  maxWidthTablet: 43.5,
  maxWidthMobile: 21.438,
  maxTabMenuWidth: 54,

  screenMaxWidth: 90,
  videoMaxWidth: 64,
  contentResultWidth: 54,
  blockMaxWidthMobile: 37.5,
  mobileMenuMaxWidth: 19.375,

  productImageTinyWidth: 5,

  //imageHeight
  thumbnailImageHeight: 3,
  productImageTinyHeight: 4,
  productImageMaxHeight: 13.75,
  productImageMaxHeightMedium: 45,
  productImageMaxHeightLarge: 46,
  heroImageMaxHeight: 45,
  heroImageMediumHeight: 32.5,
  heroBlockWideScreenHeight: 37.5,
  carouselImageHeight: 13.75,
  carouselImageHeightMedium: 23.125,
  heroImageSmallHeight: 46.19,
  contentBlockImageMinHeight: 15,
};
type SizeConstants = typeof sizeConstants;
type Sizes = Record<keyof SizeConstants, string>;

// Convert size constants to string with 'rem' unit for CSS usage.
const sizes = ObjectEntries(sizeConstants).reduce<Sizes>(
  (acc, [key, value]) => {
    acc[key] = `${value}rem`;
    return acc;
  },
  {} as Sizes
);

type keyframe = ReturnType<typeof keyframes>;
type cssType = ReturnType<typeof css>;

//Animations
// Define interfaces for keyframe and CSS animations for consistent animation usage.
// KeyframeAnimations interface for defining various keyframe-based animations.
interface KeyframeAnimations {
  animationLoadingStrokeDash: keyframe;
  animationFrames: keyframe;
  animationLoader: keyframe;
  animationRotation: keyframe;
  animationTranslateInDesktop: keyframe;
  animationTranslateInMobile: keyframe;
  rotation: keyframe;
  slideFromLeftAnimation: keyframe;
  slideFromRightAnimation: keyframe;
  shimmerFromLeft: keyframe;
}

// CSSAnimations interface for defining CSS-based animations and transition settings.
interface CSSAnimations {
  timingFn: string;
  easeInOut: string;
  easeOut: string;
  rollInAnimation: cssType;
  rotateAnimation: cssType;
  shimmerSkeletonAnimation: cssType;
  slideAnimation: {
    left: cssType;
    right: cssType;
    none: string;
  };
  // Add other CSS animations as needed
}

// ThemeAnimations interface combining both keyframe and CSS animations.
interface ThemeAnimations {
  keyframes: KeyframeAnimations;
  cssAnimations: CSSAnimations;
}

// Define specific keyframes and CSS animations.
const animationLoadingStrokeDash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: ${({ theme }) => theme.color.decorationSubtle};
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
    stroke: ${({ theme }) => theme.color.onSurface};
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
    stroke: ${({ theme }) => theme.color.decorationSubtle};
  }
`;

const animationFrames = keyframes`
0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const animationLoader = keyframes`
  0% {
    transform: translate(0,0);
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55);
  }
  50% {
    transform: translate(0,120px);
    animation-timing-function: cubic-bezier(0,0.45,0.55,0.9);
  }
  100% {
    transform: translate(0,0);
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55);
  }
`;

const animationRotation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const animationTranslateInDesktop = keyframes`
  0% {
    transform: translateY(-68px);
  }
  30% {
    transform: translateY(68px);
  }
  50% {
    transform: translateY(68px);
  }
  80% {
    transform: translateY(68px);
  }
  100% {
    transform: translateY(-68px);
  }
`;

const animationTranslateInMobile = keyframes`
  0% {
    transform: translateY(101vh);
  }
  30% {
    transform: translateY(calc(100vh - 170px));
  }
  50% {
    transform: translateY(calc(100vh - 170px));
  }
  80% {
    transform: translateY(calc(100vh - 170px));
  }
  100% {
    transform: translateY(101vh);
  }
`;

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }`;

const slideFromLeftAnimation = keyframes`
  from {
   transform: translateX(100%);
 }
 to {
   transform: translateX(0);
 }
`;

const slideFromRightAnimation = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
`;
export const shimmerFromLeft = keyframes` 
  to {
    background-position: 100% 0%;
  }
`;

// Define CSS animations
const timingFn = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
const easeInOut = 'ease-in-out';
const easeOut = 'ease-out';

const rollInAnimation = css`
  opacity: 0;
  transform: translateX(-50%) rotate(-180deg);
  transition: all ease-in-out ${({ theme }) => theme.transitions.t300};
  &:hover {
    opacity: 1;
    transform: translateX(50%) rotate(0deg);
  }
`;
const rotateAnimation = css`
  transition-duration: ${({ theme }) => theme.transitions.t100};
  transition-property: all;
  animation: ${rotation};
`;

const slideAnimationLeft = css`
  animation: ${slideFromLeftAnimation};
  animation-duration: ${({ theme }) => theme.transitions.t300};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;

const slideAnimationRight = css`
  animation: ${slideFromRightAnimation};
  animation-duration: ${({ theme }) => theme.transitions.t300};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;
const shimmerSkeletonAnimation = css`
  animation: ${shimmerFromLeft};
  animation-duration: ${({ theme }) => theme.transitions.t1500};
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const slideAnimation = {
  left: slideAnimationLeft,
  right: slideAnimationRight,
  none: '',
};

// Combine all keyframe and CSS animations into a single object.
const animations: ThemeAnimations = {
  keyframes: {
    animationLoadingStrokeDash,
    animationFrames,
    animationLoader,
    animationRotation,
    animationTranslateInDesktop,
    animationTranslateInMobile,
    rotation,
    slideFromLeftAnimation,
    slideFromRightAnimation,
    shimmerFromLeft,
  },
  cssAnimations: {
    timingFn,
    easeInOut,
    easeOut,
    rollInAnimation,
    rotateAnimation,
    slideAnimation,
    shimmerSkeletonAnimation,
  },
};

// Export the complete theme object, aggregating all styles and animations.
export default {
  borders,
  fontSizes,
  fontFamilies,
  fontWeights,
  letterSpacings,
  lineHeights,
  radius,
  shadows,
  sizes,
  space,
  zIndices,
  sizeConstants,
  transitions,
  animations,
} as const;
