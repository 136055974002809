//New const for stitches and useMedia
//Add when needed
export const mediaQueryTypes = {
  //Layout breakpoints
  bpXL: '(min-width: 1441px)',
  bpL: '(min-width: 1025px) and (max-width: 1440px)',
  bpM: '(min-width: 720px) and (max-width: 1024px)',
  bpS: '(min-width: 376px) and (max-width: 720px)',
  bpXS: '(max-width: 375px)',

  //Device type Break points
  bpMin601: '(min-width: 601px)',
  bpMin961: '(min-width: 960px)',
  bpMin1281: '(min-width: 1281px)',
  bpMin1921: '(min-width: 1921px)',

  // Common breakpoints 2022
  bpMin376: '(min-width: 376px)', // mobile
  bpMin721: '(min-width: 721px)', // tablet
  bpMin1025: '(min-width: 1025px)', // laptop
  bpMin1441: '(min-width: 1441px)', // large desktop
  bpMin2561: '(min-width: 2561px)', // Wide screen desktop

  //Max
  bpMax600: '(max-width: 600px)',
  bpMax960: '(max-width: 960px)',
  bpMax1280: '(max-width: 1280px)',
  bpMax1920: '(max-width: 1920px)',

  // Common breakpoints 2022
  bpMax375: '(max-width: 375px)', // mobile
  bpMax720: '(max-width: 720px)', // tablet
  bpMax1024: '(max-width: 1024px)', // laptop
  bpMax1440: '(max-width: 1440px)', // large desktop
  bpMax2560: '(max-width: 2560px)', // Smaller than wide screen desktop

  //Custom breakpoints
  bpMin300: '(min-width: 300px)',
  bpSmallDesktop: '(min-width: 1025px) and (max-width: 1280px)',
};
