import {
  useCurrentPage,
  useFetchCurrentPage,
} from 'app/shared/currentPage/currentPageSlice';
import { useCustomer } from 'app/shared/customer/customerSlice';
import LoadingPageType from 'Cms/Pages/LoadingPageType/LoadingPageType';
import ProductPageSkeleton from 'Commerce/Pages/ProductPage/ProductPageSkeleton';
import { JSX, lazy, Suspense, useEffect } from 'react';
import { pushGTMPageView } from 'shared/analytics/gtmEvents';

/* not possible to use full dynamic imports, in server context it wont be able to locate chunks */
const lazyPages = {
  StartPage: lazy(
    () => import(/* webpackPrefetch: true */ 'Cms/Pages/StartPage/StartPage')
  ),
  StandardPage: lazy(
    () =>
      import(/* webpackPrefetch: true */ 'Cms/Pages/StandardPage/StandardPage')
  ),
  ProductBase: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/ProductPage'
      )
  ),
  ProductPage: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/ProductPage'
      )
  ),
  QuickOrderPage: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/QuickOrder/QuickOrderPage'
      )
  ),
  BlockPreviewPage: lazy(() => import('./BlockPreviewPage')),
  CategoryBase: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/CategoryPage/CategoryPage'
      )
  ),
  CategoryPage: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/CategoryPage/CategoryPage'
      )
  ),
  CartPage: lazy(
    () => import(/* webpackPrefetch: true */ 'Commerce/Pages/Cart/CartPage')
  ),
  CheckoutPage: lazy(
    () =>
      import(/* webpackPrefetch: true */ 'Commerce/Pages/Checkout/CheckoutPage')
  ),
  OrderConfirmationPage: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/OrderConfirmation/OrderConfirmationPage'
      )
  ),
  SearchPage: lazy(
    () => import(/* webpackPrefetch: true */ 'Cms/Pages/SearchPage/SearchPage')
  ),
  StorefrontPage: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/StorefrontPage/StorefrontPage'
      )
  ),
  AccountPage: lazy(
    () =>
      import(/* webpackPrefetch: true */ 'Cms/Pages/AccountPage/AccountPage')
  ),
  ForgotPasswordPage: lazy(
    () =>
      import(
        /* webpackPrefetch: true */ 'Cms/Pages/ForgotPasswordPage/ForgotPasswordPage'
      )
  ),
  NotFoundPage: lazy(() => import('Cms/Pages/NotFoundPage/NotFoundPage')),
  ErrorPage: lazy(() => import('Cms/Pages/ErrorPage/ErrorPage')),
  CampaignPage: lazy(
    () =>
      import(/* webpackPrefetch: true */ 'Cms/Pages/CampaignPage/CampaignPage')
  ),
};

type KexPageType = keyof typeof lazyPages;
type lazyPageSkeletonProps = Record<KexPageType, JSX.Element>;

const lazyPageSkeletons: Partial<lazyPageSkeletonProps> = {
  ProductBase: <ProductPageSkeleton />,
};

const KexPage = () => {
  const { pageId, pageType, pageTitle, isLoading } = useCurrentPage();
  const { authenticated } = useCustomer();
  const fetchPage = useFetchCurrentPage();

  const getlazySkeleton = (pageType: KexPageType) => {
    return lazyPageSkeletons[pageType] || <LoadingPageType />;
  };

  useEffect(() => {
    window.addEventListener('popstate', fetchPage);

    return () => {
      window.removeEventListener('popstate', fetchPage);
    };
  }, []);

  useEffect(() => {
    if (pageId) {
      // Register GTM event
      pushGTMPageView(
        { pageTitle, url: window.location.pathname },
        authenticated
      );
    }
  }, [pageId]);

  const LazyPage = lazyPages[pageType as KexPageType];
  return !isLoading ? (
    <Suspense fallback={getlazySkeleton(pageType as KexPageType)}>
      <LazyPage />
    </Suspense>
  ) : (
    <LoadingPageType />
  );
};

/**
 * Navigate function that accepts either a full URL, a path, or a numeric value for history navigation.
 * @param {string | number} destination - The destination can be a full URL, a path, or a number.
 * @param {() => void} [callback] - Optional callback function to be executed after navigation.
 * @param {boolean} [replace] - If true, the navigation will replace the current history entry.
 */
export const navigate = (
  destination: string | number,
  replace?: boolean,
  callback?: () => void
) => {
  if (typeof destination === 'number') {
    window.history.go(destination);
  } else if (typeof destination === 'string') {
    if (
      destination.startsWith('http://') ||
      destination.startsWith('https://')
    ) {
      window.location.href = destination;
    } else {
      if (replace) window.history.replaceState({}, '', destination);
      else window.history.pushState({}, '', destination);
      window.dispatchEvent(new PopStateEvent('popstate'));
      window.scrollTo(0, 0);
    }
    callback && callback();
  }
};

export const useNavigate = () => navigate;

export default KexPage;
