/**
 * Async thunk to handle user login.
 *
 * @async
 * @function loginUser
 * @returns {Promise<Object>} - A promise representing the user data.
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetcherResponse, http } from 'app/fetch/fetcher';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import UserCredentialsModels from 'Models/Pages/AccountPage/UserCredentialsModel.interface';
import { setError } from '../appError/appErrorSlice';
import { fetchResource } from '../utils/sliceUtils';
import { CustomerState } from './customerSlice';

export const userUrl = 'api/customer';

// Define the async thunk for login
export const signInUser = createAsyncThunk(
  'api/customer/login',
  (credentials: string, { rejectWithValue, dispatch }) => {
    const url = userUrl + '/login';

    return http.handleHttpResponse<CustomerState>(
      http.post(`${getAPIBasedOnSystem('cms')}/${url}`, {
        headers: {
          Authentication: `Basic ${credentials}`,
        },
      }),
      dispatch,
      (err) => rejectWithValue(err)
    );
  }
);

/**
 * Async thunk to handle user logout.
 *
 * @async
 * @function signOutUser
 * @returns {Promise<void>} - A promise that resolves when the user is logged out.
 */
// Define the async thunk for logout
export const signOutUser = createAsyncThunk(
  'user/logout',
  (_, { dispatch }) => {
    const url = userUrl + '/logout';

    return fetchResource<CustomerState>(
      `${getAPIBasedOnSystem('cms')}/${url}`,
      dispatch,
      setError
    );
  }
);

/**
 * Async thunk to handle user logout.
 *
 * @async
 * @function registerUser
 * @returns {Promise<void>} - A promise that resolves when the user is logged out.
 */
// Define the async thunk for logout
export const registerUser = createAsyncThunk(
  'user/register',
  (credentials: UserCredentialsModels) => {
    const url = userUrl + '/register';

    return http.handleHttpResponse<CustomerState>(
      http.post(`${getAPIBasedOnSystem('cms')}/${url}`, {
        body: credentials,
      })
    );
  }
);

/**
 *Method to handle user update.
 *
 * @async
 * @function updateUser
 * @returns {Promise<CustomerState>} - A promise that resolves when the user is updated.
 */

export const updateUser = (
  customer: CustomerState
): FetcherResponse<CustomerState> => {
  const url = userUrl + '/update';

  return http.post<CustomerState>(`${getAPIBasedOnSystem('cms')}/${url}`, {
    body: customer,
  });
};

/**
 * Async thunk to fetch user data.
 *
 * @async
 * @function user
 * @returns {Promise<Object>} - A promise representing the user data.
 */
export const fetchCustomerStateAsync = createAsyncThunk(
  'user',
  (_, { dispatch }) => {
    return fetchResource<CustomerState>(
      `${getAPIBasedOnSystem('cms')}/${userUrl}`,
      dispatch,
      setError
    );
  }
);
