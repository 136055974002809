import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { setError } from '../appError/appErrorSlice';
import { fetchResource } from '../utils/sliceUtils';
export interface TranslationsStateResponse {
  translations: { [key: string]: string };
}

const translation: string = 'api/site/translations';

export const fetchTranslationStateAsync = createAsyncThunk(
  'translations/fetch',
  (_, { dispatch }) => {
    return fetchResource<TranslationsStateResponse>(
      `${getAPIBasedOnSystem('cms')}/${translation}`,
      dispatch,
      setError
    );
  }
);
