import ColorTokensType from '../Settings/colorTypes';

export type ColorType = keyof typeof lightColors;

const lightColors: ColorTokensType = {
  //region Universal
  white: '#FFFFFF',

  //region Base - Used for static backgrounds and borders
  surface: '#FFFFFF',
  decorationBackgroundOne: '#F9F9FA',
  decorationSubtle: '#F1F1F2',
  decorationEmphasized: '#E4F0EB',
  decorationEmphasizedStrong: '#E4F0EB',
  decorationAccent: '#FEF2E4',
  decorationAccentStrong: '#FEF2E4',
  inverse: '#221E20',
  //endregion

  //region Text and icon
  onSurface: '#221E20',
  onElevation: '#221E20',
  onSurfaceSubtle: '#2D292B',
  onSurfaceSubtleSecondary: '6E747B',
  onInteractivePrimary: '#F9F9FA',
  onInteractiveSecondary: '#221E20',
  onInteractiveEmphasized: '#221E20',
  onInverse: '#F9F9FA',
  onStatic: '#221E20',
  onWarning: '#FFFFFF',
  //endregion

  //region Semantic
  semanticSuccess: '#1B7E5A',
  semanticInformative: '#116AEA',
  semanticError: '#C83A3A',
  semanticWarning: '#C83A3A',
  semanticWarningHover: '#A73030',
  semanticWarningPressed: '#852727',
  //endregion

  //region Elevation
  elevateOne: '#F1F1F2',
  elevateTwo: '#F9F9FA',
  elevateThree: '#C9CBCD',
  overlayOne: 'FFFFFFB3',
  underlayOne: '00000066',
  //endregion
  //region Interactive primary background
  interactivePrimaryBackgroundDefault: '#2D292B',
  interactivePrimaryBackgroundHover: '#221E20',
  interactivePrimaryBackgroundPressed: '#221E20',
  //endregion

  //region Interactive Secondary background
  interactiveSecondaryBackgroundDefault: '#F1F1F2',
  interactiveSecondaryBackgroundHover: '#F9F9FA',
  interactiveSecondaryBackgroundPressed: '#F1F1F2',
  //endregion

  //region Interactive Emphasised background
  interactiveEmphasizedBackgroundDefault: '#F78F1E',
  interactiveEmphasizedBackgroundHover: '#EA881C',
  interactiveEmphasizedBackgroundPressed: '#DE801B',
  //endregion

  //region Interactive Subtle background
  interactiveSubtleBackgroundDefault: '#1B7E5A',
  interactiveSubtleBackgroundHover: '#187151',
  interactiveSubtleBackgroundPressed: '#176B4C',
  //endregion

  //region Interactive Border
  interactiveBorderActive: '#221E20',
  interactiveBorderActiveInverse: '#1B7E5A',
  interactiveBorderSecondaryDefault: '#C9CBCD',
  interactiveBorderSecondaryHover: '#808284',
  interactiveBorderSecondaryPressed: '#2D292B',
  interactiveBorderSelected: '#221E20',
  interactiveBorderInverse: '#E3E3E5',
  //endregion

  // Gradient Opacity
  decorationGradientOpacity:
    'linear-gradient(181.09deg, rgba(255, 255, 253, 0) 0.93%, FFFFFD 74.53%)',

  //region Interactive Disabled
  interactiveDisabledOne: '#C9CBCD',
  interactiveDisabledTwo: '#808284',
  //endregion

  //region Static colors
  staticBrand1: '#116AEA',
  staticBrand2: '#C83A3A',
  staticBrand3: '#F78F1E',
  staticWhite: '#FFFFFF',
  staticBlack: '#000000',
  //endregion

  //region Link
  linkDefault: '#221E20',
  linkHover: '#2D292B',
  //endregion
};

export default lightColors;
