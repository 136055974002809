import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import HeaderModel from 'Models/Headers/HeaderModel.interface';
import { http } from 'app/fetch/fetcher';

export const fetchHeaderDataAsync = () => {
  return fetchHeaderAsync(`api/content/header`);
};

export const fetchHeaderAsync = createAsyncThunk(
  'api/content/header',
  async (url: string) => {
    return http.handleHttpResponse<HeaderModel>(
      http.get(`${getAPIBasedOnSystem('cms')}/${url}`)
    );
  }
);
