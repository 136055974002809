import { AnyAction, isPlainObject, ThunkDispatch } from '@reduxjs/toolkit';
import {
  checkError,
  exceptionResponse,
  successResponse,
} from 'app/helpers/httpHelper';
import { pushError } from 'app/shared/appError/appErrorSlice';
import { plainState } from 'app/store';
import { ErrorDialogProps } from 'DesignComponents/Organisms/Dialog/ErrorDialog';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { navigate } from 'Kex/KexPage';

interface KexRequestInit extends Omit<RequestInit, 'body'> {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';
  body?: object;
  headers?: Record<string, string>;
}
export type ExternalRequestInit = Omit<KexRequestInit, 'method'>;

export type FetcherResult<ResponseType> =
  | ExceptionResponse
  | SuccessResponse<ResponseType>
  | FailureResponse;
export type FetcherResponse<ResponseType> = Promise<
  FetcherResult<ResponseType>
>;

const fetcher = <ResponseType>(
  endpoint: string,
  requestInit: KexRequestInit
): FetcherResponse<ResponseType> => {
  const language = plainState.getValue('language', 'en');

  const { method, body, signal, headers } = requestInit;

  const requestHeaders: HeadersInit = {
    ...headers,
    Accept: 'application/json',
    'Content-Language': language,
    'Content-Type': 'application/json',
    'Context-Url':
      headers && headers['Context-Url']
        ? headers['Context-Url']
        : `${getAPIBasedOnSystem('api')}/${language}`,
    'X-BackendService':
      headers && headers['System']
        ? headers['System']
        : plainState.getValue('cms', import.meta.env.VITE_TARGET_CMS),
    'X-ApplicationId': '1047',
  };
  if (headers && (headers as typeof requestHeaders).Authentication) {
    requestHeaders.Authorization = (
      headers as typeof requestHeaders
    ).Authentication;
  } else
    requestHeaders.Authorization = `Bearer ${plainState.getValue('token', '')}`;

  return fetch(endpoint, {
    headers: requestHeaders,
    // Include http only cookies if origin matches
    credentials: 'include',
    method,
    signal,
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      // Attempt to retrieve the content type of the response
      const contentType = response.headers.get('Content-Type');
      let parsedBody: ResponseType | string | undefined;

      if (contentType && contentType.includes('application/json')) {
        try {
          const clone = await response.clone().text();
          parsedBody = clone ? await response.json() : undefined;
        } catch {
          return exceptionResponse(500, 'Response JSON parsing failed.');
        }
      } else {
        parsedBody = await response.text();
      }
      if (parsedBody && response.status === 200) {
        if (isRedirectResponse(parsedBody)) navigate(parsedBody.redirectUrl);
        return successResponse(parsedBody as ResponseType, response.status);
      }
      return checkError(response, parsedBody as ResponseType);
    })
    .catch((error) => {
      return checkError(error, {} as ResponseType);
    });
};

export const http = {
  get: <ResponseType>(endpoint: string, requestInit?: ExternalRequestInit) =>
    fetcher<ResponseType>(endpoint, { ...requestInit, method: 'GET' }),

  post: <ResponseType>(endpoint: string, requestInit?: ExternalRequestInit) =>
    fetcher<ResponseType>(endpoint, { ...requestInit, method: 'POST' }),

  put: <ResponseType>(endpoint: string, requestInit?: ExternalRequestInit) =>
    fetcher<ResponseType>(endpoint, { ...requestInit, method: 'PUT' }),

  patch: <ResponseType>(endpoint: string, requestInit?: ExternalRequestInit) =>
    fetcher<ResponseType>(endpoint, { ...requestInit, method: 'PATCH' }),

  delete: <ResponseType>(endpoint: string, requestInit?: ExternalRequestInit) =>
    fetcher<ResponseType>(endpoint, { ...requestInit, method: 'DELETE' }),

  option: <ResponseType>(endpoint: string, requestInit?: ExternalRequestInit) =>
    fetcher<ResponseType>(endpoint, { ...requestInit, method: 'OPTIONS' }),

  /*
    handleHttpResponse takes care of a response from fetcher by checking data schema and matching it to the
    3 available type: SuccessResponse, FailureResponse and ExceptionResponse. It returns result if query was SuccessResponse.
    Other paths register an error in the error slice.
    */
  handleHttpResponse: async <ResponseType>(
    fetchPromise: FetcherResponse<ResponseType>,
    dispatch?: ThunkDispatch<unknown, unknown, AnyAction>,
    setError?: (result: FailureResponse | ExceptionResponse | undefined) => void
  ) => {
    let result: FetcherResult<ResponseType> | undefined;

    try {
      result = await fetchPromise;

      if (result.isSuccess) {
        return result;
      }

      if (result.isFailure && setError) {
        setError(result);
      }

      if (result.isException) {
        const { message } = result;
        if (dispatch) pushError({ message }, dispatch);
        else if (setError) setError(result);
      }

      return undefined;
      // eslint-disable-next-line
    } catch (err: any) {
      if (setError) setError(result as ExceptionResponse);
      throw err;
    }
  },

  /*
    handleHttpSuccessResponse takes care of a response from fetcher by checking data schema and matching it to the
    SuccessResponse type. It returns result if query was SuccessResponse.
    Other paths returns undefined and no error handling is performed.
    */
  handleHttpSuccessResponse: async <ResponseType>(
    fetchPromise: FetcherResponse<ResponseType>
  ) => {
    const result = await fetchPromise;

    if (result.isSuccess) {
      return result;
    }

    return undefined;
  },
};

export interface SuccessResponse<T> {
  isSuccess: true;
  isFailure?: false;
  isException?: false;
  status: number;
  data: T;
}

export interface FailureResponse {
  isSuccess?: false;
  isFailure: true;
  isException?: false;
  status: number;
  data: ErrorDialogProps;
}

export interface ExceptionResponse {
  isSuccess?: false;
  isFailure?: false;
  isException: true;
  status: number;
  message: string;
  data: ErrorDialogProps;
}

export interface RedirectResponse {
  redirectUrl: string;
}

const isRedirectResponse = (body?: unknown): body is RedirectResponse =>
  isPlainObject(body) && 'redirectUrl' in body;
