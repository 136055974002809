import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from 'app/fetch/fetcher';
import { toParamString } from 'app/helpers/urlHelper';
import { pushError, setError } from 'app/shared/appError/appErrorSlice';
import ProductCardModel from 'Commerce/Molecules/Card/ProductCard/ProductCardModel.interface';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { ProductResultModel } from 'shared/search/models/searchModels';

export interface CategorySearchRequest {
  id: string;
  params?: [string, string | number][];
  skip?: number;
  take?: number;
  result?: ProductCardModel[];
  fetchFresh?: boolean;
}

export const fetchCategorySearchResult = createAsyncThunk(
  'category/search', // Action type prefix (NOTE: To be changed later)
  (searchRequest: CategorySearchRequest, { dispatch }) => {
    const url = `api/categories/${searchRequest.id}/products?${toParamString([
      ...(searchRequest.params ? searchRequest.params : []),
    ])}`;

    return http
      .handleHttpResponse<ProductResultModel>(
        http.get<ProductResultModel>(`${getAPIBasedOnSystem('cms')}/${url}`),
        dispatch,
        (err) =>
          setError({
            message: err
              ? err.data.message
              : 'Failed to fetch category products',
          })
      )
      .catch((error) => {
        pushError(error, dispatch);
      });
  }
);
